import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Modal, Button, Nav, Tab } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import swal from "sweetalert";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext"

//images
import { Logout } from '../../../store/actions/AuthActions';
import Api from '../../../services/Api';

const HomePage = () => {
	const { changeBackground } = useContext(ThemeContext);

	const [brokerCreds, setBrokerCreds] = useState()
	const [logs, setLogs] = useState()
	const [paidUsers, setPaidUsers] = useState()
	const [telegramContent, setTelegramContent] = useState("")
	const [logModal, setLogModal] = useState(false);
	const [telegramPostModal, setTelegramPostModal] = useState(false);
	const [telegramPictures, setTelegramPictures] = useState([]);
	const [telegramCoverPicture, setTelegramCoverPicture] = useState(null);

	useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
		const tokenDetailsString = localStorage.getItem('userDetails');
		let tokenDetails = '';
		if (!tokenDetailsString) {
			Logout();
			return;
		}
		tokenDetails = JSON.parse(tokenDetailsString);
		Api.getBrokerCredentials(tokenDetails.uniqueId).then(response => {
			setBrokerCreds(response.data.data)
		});
		Api.getLogs(tokenDetails.uniqueId).then(response => {
			setLogs(response.data.data)
		});
		if (tokenDetails.uniqueId == "df1279c5e3") {
			Api.getPaidUsers().then(response => {
				setPaidUsers(response.data.data)
			});
		}
	}, []);

	const getLogs = () => {
		const tokenDetailsString = localStorage.getItem('userDetails');
		let tokenDetails = '';
		tokenDetails = JSON.parse(tokenDetailsString);
		Api.getLogs(tokenDetails.uniqueId).then(response => {
			setLogs(response.data.data)
			toast.success("Loglar yenilendi!", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
			});
		});
	}

	const getTvUsername = () => {
		const tokenDetailsString = localStorage.getItem('userDetails');
		let tokenDetails = '';
		tokenDetails = JSON.parse(tokenDetailsString);
		return tokenDetails.tradingViewUsername;
	}

	const getUniqueId = () => {
		const tokenDetailsString = localStorage.getItem('userDetails');
		let tokenDetails = '';
		tokenDetails = JSON.parse(tokenDetailsString);
		return tokenDetails.uniqueId;
	}

	const getAccountCreatedAt = () => {
		const tokenDetailsString = localStorage.getItem('userDetails');
		let tokenDetails = '';
		tokenDetails = JSON.parse(tokenDetailsString);
		return tokenDetails.createdAt.split("T")[0];
	}

	const getAccountUpdatedAt = () => {
		const tokenDetailsString = localStorage.getItem('userDetails');
		let tokenDetails = '';
		tokenDetails = JSON.parse(tokenDetailsString);
		const date = new Date(tokenDetails.updatedAt.split("T")[0]);
		date.setFullYear(date.getFullYear() + 1);
		const updatedDate = date.toISOString().split("T")[0];
		return updatedDate;
	}

	function formatCreatedAt(data) {
		const datePart = data.created_at.split("T")[0];
		const timePart = data.created_at.split("T")[1].split(".")[0];

		let logEntries = [];

		if (typeof data.log === "string") {
			try {
				const parsed = JSON.parse(data.log);
				if (Array.isArray(parsed)) {
					logEntries = parsed;
				} else {
					logEntries = [data.log];
				}
			} catch (e) {
				logEntries = [data.log];
			}
		} else {
			logEntries = data.log;
		}

		if (logEntries[0] !== undefined) {
			if (logEntries[0].includes("BIST")) {
				const timeArray = timePart.split(":");
				const newHour = parseInt(timeArray[0], 10) + 3;
				return `${datePart} ${newHour}:${timeArray[1]}:${timeArray[2]}`;
			} else {
				return `${datePart} ${timePart}`;
			}
		} else {
			try {
				const parsed = JSON.parse(data.log);
				if (parsed?.data?.object?.created) {
					const d = new Date(0);
					d.setUTCSeconds(parsed.data.object.created);
					return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
				}
			} catch (e) {
				// fallback
			}

			const d = new Date(data.created_at);
			return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
		}
	}

	const submitTelegramPost = () => {
		Api.sendTelegramPost({ pictures: telegramPictures, telegramContent: telegramContent, coverPicture: telegramCoverPicture })
			.then((response) => {
				console.log("response: ", response.data)
			}).catch(err => {
				console.log("err: ", err)
			})
	}

	const getLogsForAnotherUser = (unique_id) => {
		Api.getLogs(unique_id).then(response => {
			setLogs(response.data.data)
			toast.success("Loglar yenilendi!", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
			});
		});
	}

	const isDangerOrSuccess = (logData) => {
		let logEntries = [];

		// Updated error keywords (case-insensitive)
		const errorKeywords = [
			"amadi", "viop_id", "stock_id", "false", "yetersiz", "xceeds", "basarisiz", "error"
		];

		if (typeof logData === "string") {
			try {
				const parsed = JSON.parse(logData);
				if (Array.isArray(parsed)) {
					logEntries = parsed;
				} else {
					logEntries = [logData];
				}
			} catch (e) {
				logEntries = [logData]; // Not parsable JSON
			}
		} else {
			logEntries = logData;
		}

		// Check the last log entry OR the whole string if only one
		if (logEntries[0] !== undefined) {
			const lastEntry = logEntries[logEntries.length - 1].toLowerCase();
			return errorKeywords.some(keyword => lastEntry.includes(keyword))
				? "btn btn-danger"
				: "btn btn-success";
		}

		return "btn btn-success";
	};

	const getLogTitle = (logs) => {
		if (typeof logs === "string") {
			try {
				const parsed = JSON.parse(logs);
				if (Array.isArray(parsed)) {
					let logEntries = parsed
					const orderEntry = logEntries[4];
					const cryptoOrderEntry = logEntries[3];
					const orderEntryExtra = logEntries[5];
					const loginEntry = logEntries[1];
					const firstEntry = logEntries[0];

					// Check if the first entry is related to Telegram
					if (firstEntry && firstEntry.includes("Telegram icin")) {
						return firstEntry.split(" ").slice(0, 5).join(" ");
					}

					// Check for login attempts with or without OTP
					if (loginEntry) {
						if (loginEntry.includes("OTPsiz")) {
							return `${firstEntry.split(" ").slice(0, 2).join(" ").toUpperCase()} | ADIM 1`;
						}
						if (loginEntry.includes("OTP:")) {
							return `${firstEntry.split(" ").slice(0, 2).join(" ").toUpperCase()} | ADIM 2`;
						}
					}

					// Helper function to extract trading pair info
					const getTradingPair = (entry) => {
						const words = entry.split(" ");
						const keywordIndex = words.findIndex((word, index) =>
							(word === "ile" && (words[index + 1] === "spot" || words[index + 1] === "vadeli"))
							|| (word == "Futures" || word == "Spot")
						);

						if (keywordIndex >= 0) {
							let pair = `${firstEntry.split(" ")[0]} | ${words[(keywordIndex - 1 == -1 ? keywordIndex + 8 : keywordIndex - 1)]}`;
							if (words.includes("spot") || words.includes("Spot")) {
								pair += " | Spot";
							}
							if (words.includes("vadeli") || words.includes("Futures")) {
								pair += " | Vadeli";
							}
							return pair;
						}
						return null;
					};

					// Check for trading pair info in orderEntry or orderEntryExtra
					const orderPair = orderEntry ? getTradingPair(orderEntry) : null;
					if (orderPair) return orderPair;

					const extraOrderPair = orderEntryExtra ? getTradingPair(orderEntryExtra) : null;
					if (extraOrderPair) return extraOrderPair;

					const extraCryptoOrderPair = orderEntryExtra ? getTradingPair(cryptoOrderEntry) : null;
					if (extraCryptoOrderPair) return extraCryptoOrderPair;

					// Fallback to displaying amount and customer details
					const dataObject = logEntries?.data?.object;
					if (dataObject) {
						const amount = dataObject.currency_conversion?.amount_total || dataObject.amount_total;
						const customerName = dataObject.customer_details?.name || "Unknown";
						const successUrl = dataObject.success_url?.split("/")[2] || "";

						return `$${amount / 100} > ${customerName}${successUrl ? ` > ${successUrl}` : ""}`;
					}

					// Check for 'spot' and 'vadeli' in the entire log
					const logString = JSON.stringify(logEntries);
					if (logString.includes("spot")) {
						return `${firstEntry || "Unknown Log Entry"} | Spot`;
					}
					if (logString.includes("vadeli")) {
						return `${firstEntry || "Unknown Log Entry"} | Vadeli`;
					}

					// Fallback for undefined first entry
					return firstEntry || "Unknown Log Entry";
				}
			} catch (e) {
				// Not a parsable JSON string, treat as plain string
			}
			return [logs]; // wrap single log string into array
		}

	};

	function renderLogItems(logModal) {
		try {
			let logEntries = logModal.log;

			// Always a string — try to parse if it's a JSON array
			if (typeof logEntries === "string") {
				try {
					const parsed = JSON.parse(logEntries);
					if (Array.isArray(parsed)) {
						logEntries = parsed;
					} else {
						logEntries = [logEntries]; // treat as plain string
					}
				} catch (e) {
					logEntries = [logEntries]; // not a JSON array string
				}
			}

			return logEntries.map((item, index) => (
				<li
					key={index}
					style={{ whiteSpace: "normal", wordBreak: "break-word" }}
				>
					{index + 1}- {item}
				</li>
			));
		} catch (e) {
			// fallback if something goes really wrong
			return JSON.stringify(logModal);
		}
	}

	return (
		<>
			<ToastContainer />
			<Modal className="fade" size="lg" show={telegramPostModal}>
				<Modal.Header>
					<Modal.Title>Telegram Postu Oluştur</Modal.Title>
					<Button
						variant=""
						className="btn-close"
						onClick={() => {
							setTelegramPostModal(false)
							setTelegramContent("")
							setTelegramPictures([])
							setTelegramCoverPicture(null)
						}}
					/>
				</Modal.Header>
				<Modal.Body>
					<div className="card profile-card card-bx m-b30">
						<form className="profile-form">
							<div className="card-body">
								<div className="row">
									<div className="col-sm-12 m-b30">
										<div className="form-check custom-checkbox mb-3 checkbox-info">
											<label className="form-label">Kapak Fotoğrafı</label>
											<input className="form-control" type="file" onChange={(event) => setTelegramCoverPicture(event.target.files[0])} />
										</div>
									</div>
									<div className="col-sm-12 m-b30">
										<div className="form-check custom-checkbox mb-3 checkbox-info">
											<label className="form-label">Telegram İçeriği</label>
											<textarea name="textarea" onChange={(event) => setTelegramContent(event.target.value)} className="form-control" value={telegramContent} />
										</div>
									</div>
									<div className="col-sm-12 m-b30">
										<div className="form-check custom-checkbox mb-3 checkbox-info">
											<label className="form-label">Strateji Fotoğrafları</label>
											<input className="form-control" type="file" multiple onChange={(event) => setTelegramPictures(Array.from(event.target.files))} />
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-success" onClick={submitTelegramPost}>Oluştur</button>
					<Button
						onClick={() => {
							setTelegramPostModal(false)
							setTelegramContent("")
							setTelegramPictures([])
							setTelegramCoverPicture(null)
						}}
						variant="danger"
					>
						Kapat
					</Button>
				</Modal.Footer>
			</Modal>
			<div className="row">
				<div className="col-xl-8">
					<div className="row">
						<div className='col-xl-12'>
							<div className="card">
								<div className="card-header">
									<h4 className="heading">Son İşlemler</h4>
									{paidUsers &&
										<select
											className="form-control"
											style={{ width: 300, background: "#40404075" }}
											defaultValue={getUniqueId()}
											onChange={(event) => getLogsForAnotherUser(event.target.value)}
										>
											{paidUsers.map((user, index) => (
												<option value={user.unique_id} key={index}>{user.first_name} {user.last_name}</option>
											))}
										</select>
									}
									<button className='btn btn-info' style={{ backgroundColor: "#068D40", borderColor: "black" }} onClick={getLogs}>Yenile</button>
								</div>
								<div className="card-body pt-0 px-0">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th>Tarih</th>
													<th>Log</th>
												</tr>
											</thead>
											<tbody>
												{logs && logs.map((data, index) => (
													<tr key={index}>
														<td>{formatCreatedAt(data)}</td>
														<td>
															<button className={isDangerOrSuccess(data.log)} onClick={() => setLogModal(data)}>{getLogTitle(data.log)}</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										{logModal && <Modal className="fade" show={logModal}>
											<Modal.Header>
												<Modal.Title>{formatCreatedAt(logModal)} Tarihindeki İşlem</Modal.Title>
												<Button
													variant=""
													className="btn-close"
													onClick={() => setLogModal(false)}
												/>
											</Modal.Header>
											<Modal.Body style={{ maxHeight: "60vh", overflowY: "auto", color: "white" }}>
												<ol style={{ color: "white", whiteSpace: "normal", wordBreak: "break-word" }}>
													{renderLogItems(logModal)}
												</ol>
											</Modal.Body>
											<Modal.Footer>
												<Button
													onClick={() => setLogModal(false)}
													variant="danger light"
												>
													Kapat
												</Button>
											</Modal.Footer>
										</Modal>}
									</div>
								</div>
							</div>
						</div>
						{(getUniqueId() == "zq3FxXUXEn" || getUniqueId() == 'df1279c5e3') &&
							<div className="col-xl-12">
								<div className="card bubles">
									<div className="card-body">
										<h2>Uzmandata Platform'a Hoş Geldiniz!</h2>
										<p>İster kendi stratejilerinizi oluşturun, ister özel stratejiler üretin.</p>
										{/* <Link to={"/strategies"} className="btn btn-primary" style={{ backgroundColor: "#068D40", borderColor: "black" }}>Stratejilere Git</Link> */}
										<button className='btn btn-info' onClick={() => setTelegramPostModal(true)}>Telegram Postu Oluştur</button>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
				<div className="col-xl-4">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-header border-0 pb-0">
									<div>
										<h2 className="heading">Borsa Bağlantıları</h2>
										<p className="fs-16">Aynı anda sadece 1 adet aracı kurum ve kripto borsası bağlayabilirsiniz.</p>
									</div>
								</div>
								<div className="card-body pt-0 px-0">
									<div className="previews-info-list">
										<div className="pre-icon">
											<div className="ms-2">
												<h6>Binance Global</h6>
												{brokerCreds && brokerCreds.find(item => item.broker_credential_id === 10) ? <span>{brokerCreds.find(item => item.broker_credential_id === 10).updated_at.split("T")[0]}</span> : <span>Bağlı Değil</span>}
											</div>
										</div>
										<div className="count">
											{brokerCreds && brokerCreds.find(item => item.broker_credential_id === 10) ? (
												<button className="btn btn-success">Bağlandı</button>
											) : (
												<button
													className="btn btn-primary"
													onClick={() => swal({
														title: "Çok Yakında",
														text: "Binance hesabınızı direkt platform üzerinden bağlamak için çalışmalarımız devam ediyor. Bu esnada hesabınızı bağlamak için eğitim panelimizdeki Kripto Bot Kurulumları sayfasındaki videoları izleyebilir ve Telegram üzerinden hesabınızı bağlayabilirsiniz.",
														// icon: "info",
														buttons: {
															confirm: {
																text: "Tamam",
																value: true,
																visible: true,
																closeModal: true,
																className: "swal-custom-confirm",
															}
														},
														closeOnClickOutside: true,
														closeOnEsc: true,
														dangerMode: false,
													})}
													style={{
														backgroundColor: "#068D40",
														borderColor: "black",
														color: "white"
													}}
												>
													Bağla
												</button>
											)}
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<div className="ms-2">
												<h6>Deniz Yatırım</h6>
												{brokerCreds && brokerCreds.find(item => item.broker_credential_id === 7) ? <span>{brokerCreds.find(item => item.broker_credential_id === 7).updated_at.split("T")[0]}</span> : <span>Bağlı Değil</span>}
											</div>
										</div>
										<div className="count">
											{brokerCreds && brokerCreds.find(item => item.broker_credential_id === 7) ? (
												<button className="btn btn-success">Bağlandı</button>
											) : (
												<button
													className="btn btn-primary"
													onClick={() => swal({
														title: "Çok Yakında",
														text: "Deniz Yatırım hesabınızı direkt platform üzerinden bağlamak için çalışmalarımız devam ediyor. Bu esnada hesabınızı bağlamak için eğitim panelimizdeki Hisse Bot Kurulumları sayfasındaki videoları izleyebilir ve Telegram üzerinden hesabınızı bağlayabilirsiniz.",
														// icon: "info",
														buttons: {
															confirm: {
																text: "Tamam",
																value: true,
																visible: true,
																closeModal: true,
																className: "swal-custom-confirm",
															}
														},
														closeOnClickOutside: true,
														closeOnEsc: true,
														dangerMode: false,
													})}
													style={{
														backgroundColor: "#068D40",
														borderColor: "black",
														color: "white"
													}}
												>
													Bağla
												</button>
											)}
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<div className="ms-2">
												<h6>Trive Yatırım</h6>
												{brokerCreds && brokerCreds.find(item => item.broker_credential_id === 1) ? <span>{brokerCreds.find(item => item.broker_credential_id === 2).updated_at.split("T")[0]}</span> : <span>Bağlı Değil</span>}
											</div>
										</div>
										<div className="count">
											{brokerCreds && brokerCreds.find(item => item.broker_credential_id === 1) ? (
												<button className="btn btn-success">Bağlandı</button>
											) : (
												<button
													className="btn btn-primary"
													onClick={() => swal({
														title: "Çok Yakında",
														text: "Trive Yatırım hesabınızı direkt platform üzerinden bağlamak için çalışmalarımız devam ediyor. Bu esnada hesabınızı bağlamak için eğitim panelimizdeki Hisse Bot Kurulumları sayfasındaki videoları izleyebilir ve Telegram üzerinden hesabınızı bağlayabilirsiniz.",
														// icon: "info",
														buttons: {
															confirm: {
																text: "Tamam",
																value: true,
																visible: true,
																closeModal: true,
																className: "swal-custom-confirm",
															}
														},
														closeOnClickOutside: true,
														closeOnEsc: true,
														dangerMode: false,
													})}
													style={{
														backgroundColor: "#068D40",
														borderColor: "black",
														color: "white"
													}}
												>
													Bağla
												</button>
											)}
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<div className="ms-2">
												<h6>İnfo Yatırım</h6>
												{brokerCreds && brokerCreds.find(item => item.broker_credential_id === 8) ? <span>{brokerCreds.find(item => item.broker_credential_id === 9).updated_at.split("T")[0]}</span> : <span>Bağlı Değil</span>}
											</div>
										</div>
										<div className="count">
											{brokerCreds && brokerCreds.find(item => item.broker_credential_id === 8) ? (
												<button className="btn btn-success">Bağlandı</button>
											) : (
												<button
													className="btn btn-primary"
													onClick={() => swal({
														title: "Çok Yakında",
														text: "İnfo Yatırım hesabınızı direkt platform üzerinden bağlamak için çalışmalarımız devam ediyor. Bu esnada hesabınızı bağlamak için eğitim panelimizdeki Hisse Bot Kurulumları sayfasındaki videoları izleyebilir ve Telegram üzerinden hesabınızı bağlayabilirsiniz.",
														// icon: "info",
														buttons: {
															confirm: {
																text: "Tamam",
																value: true,
																visible: true,
																closeModal: true,
																className: "swal-custom-confirm",
															}
														},
														closeOnClickOutside: true,
														closeOnEsc: true,
														dangerMode: false,
													})}
													style={{
														backgroundColor: "#068D40",
														borderColor: "black",
														color: "white"
													}}
												>
													Bağla
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-12'>
							<div className="card">
								<div className="card-header d-block border-0 pb-0">
									<h2 className="heading mb-0">Hesap Bilgileriniz</h2>
									<p className="fs-16">Hesabınıza dair istatistikleri ve bilgileri görebilirsiniz.</p>
								</div>
								<div className="card-body py-0">
									<ul className="list-group list-group-flush">
										<li className="list-group-item d-flex px-0 justify-content-between align-items-center">
											<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M21.5566 23.893C21.1991 24.0359 20.8009 24.0359 20.4434 23.893L16.6064 22.3582L21 31.1454L25.3936 22.3582L21.5566 23.893Z" fill="#AC4CBC" />
												<path d="M21 20.8846L26.2771 18.7739L21 10.3304L15.7229 18.7739L21 20.8846Z" fill="#AC4CBC" />
												<path d="M15 11V26H17V11H15ZM25 16V30H27V16H25ZM20 14V32H22V14H20Z" fill="white" />
											</svg>

											<div className="w-100 ms-3">
												<div className="d-flex justify-content-between align-items-center">
													<p className="mb-0 text-black font-w600">TradingView</p>
													<button className="btn btn-info" onClick={() => swal("Kullanıcı Adını Değiştirmek Mi İstiyorsunuz?", "destek@uzmandata.com adresinden bizlere ulaşın ve indikatör yetkilerinizi bir başka hesaba taşıyalım.")} style={{ fontSize: "10px" }}>{getTvUsername()}</button>
												</div>
											</div>
										</li>
										<li className="list-group-item d-flex px-0 justify-content-between align-items-center">
											<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M21.5566 23.893C21.1991 24.0359 20.8009 24.0359 20.4434 23.893L16.6064 22.3582L21 31.1454L25.3936 22.3582L21.5566 23.893Z" fill="#AC4CBC" />
												<path d="M21 20.8846L26.2771 18.7739L21 10.3304L15.7229 18.7739L21 20.8846Z" fill="#AC4CBC" />
												<path d="M19 6H23V8H25V6H27C27.55 6 28 6.45 28 7V9H14V7C14 6.45 14.45 6 15 6H17V8H19V6ZM30 12V32C30 32.55 29.55 33 29 33H13C12.45 33 12 32.55 12 32V12H30ZM28 30H14V14H28V30Z" fill="white" />
											</svg>
											<div className="w-100 ms-3">
												<div className="d-flex justify-content-between align-items-center">
													<p className="mb-0 text-black font-w600">Üyelik Başlangıç Tarihi</p>
													<span className="ms-auto fs-15 mb-0  text-black font-w600">{getAccountCreatedAt()}</span>
												</div>
												<div className="d-flex justify-content-between align-items-center">
													<p className="mb-0 text-black font-w600">Üyelik Yenilenme Tarihi</p>
													<span className="ms-auto fs-15 mb-0  text-black font-w600">{getAccountUpdatedAt()}</span>
												</div>
											</div>
										</li>
										<li className="list-group-item d-flex px-0 justify-content-between align-items-center">
											<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user">
												<path d="M20.842 21c.09-.3.158-.608.158-.926C21 18.254 18.66 16 15.846 16H8.154C5.34 16 3 18.254 3 20.074c0 .318.068.626.158.926M16 7.083a4.5 4.5 0 1 1-8.995-.001A4.5 4.5 0 0 1 16 7.083z" />
											</svg>
											<div className="w-100 ms-3">
												<div className="d-flex justify-content-between align-items-center">
													<p className="mb-0 text-black font-w600">Üyeliği İptal Et</p>
													<button className="btn btn-danger" onClick={() => swal("Uzmandata Üyeliğinizi İptal Mi Etmek İstiyorsunuz?", "destek@uzmandata.com adresinden bizlere ulaşın ve işlemlerinizi başlatalım.")}>İptal</button>
												</div>
											</div>
										</li>
										{/* <li className="list-group-item d-flex px-0 justify-content-between align-items-center">
											<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M21 0.00012207C9.4021 0.00012207 9.15527e-05 9.40213 9.15527e-05 21C9.15527e-05 32.5979 9.4021 41.9999 21 41.9999C32.5979 41.9999 41.9999 32.5979 41.9999 21C41.9871 9.40762 32.5924 0.0129395 21 0.00012207ZM26.9999 30.0001H22.5001V34.4999C22.5001 35.3285 21.8286 36 21 36C20.1714 36 19.4999 35.3285 19.4999 34.4999V30.0001H15.0001C14.1715 30.0006 13.4995 29.3295 13.4991 28.5009C13.4991 28.1599 13.6149 27.8289 13.8282 27.5625L23.8784 15.0001H15.0001C14.1715 15.0001 13.5 14.3286 13.5 13.5C13.5 12.6715 14.1715 11.9999 15.0001 11.9999H19.4999V7.50012C19.4999 6.67157 20.1714 6.00003 21 6.00003C21.8286 6.00003 22.5001 6.67203 22.5001 7.50012V11.9999H26.9999C27.8294 12.0013 28.5005 12.6747 28.4995 13.5037C28.4991 13.8429 28.3833 14.1725 28.1718 14.4375L18.1216 26.9999H26.9999C27.8285 26.9999 28.5 27.6719 28.5 28.5C28.5 29.3286 27.8285 30.0001 26.9999 30.0001Z" fill="#5B5D81"></path>
											</svg>
											<div className="w-100 ms-3">
												<div className="d-flex justify-content-between align-items-center">
													<p className="mb-0 text-black font-w600">BTC/USD</p>
													<span className="ms-auto fs-15 mb-0  text-black font-w600">$10,245.00</span>
												</div>
												<div className="d-flex justify-content-between align-items-center">
													<span className="text-success tx-13"><i className="fa fa-caret-up me-1"></i>2.04%</span>
													<small className="text-muted ms-auto">340.5 USD</small>
												</div>
											</div>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default HomePage;